
import { defineComponent, onMounted, ref, watch } from "vue";
import { Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import * as Yup from "yup";

import { translate } from "@/core/services/HelpersFun";
import SendActivationCode from "@/views/crafted/authentication/basic-flow/SendActivationCode.vue";
import Base from "@/views/crafted/authentication/basic-flow/Base.vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Code from "@/components/Phone/Code.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "auth-login",
  components: {
    Form,
    SendActivationCode,
    Base,
    Field,
    Code,
  },
  props: {
    authData: Object,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const errorsBag = ref({});
    const resetOtp = ref(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loginWith = ref(props.authData?.loginWith);

    const code = ref();

    const reset = () =>{
      resetOtp.value = !resetOtp.value
    }

    // create form validation object for login
    const login = Yup.object().shape({
      // code:
      //   loginWith.value === "otp"
      //     ? Yup.number().required().label("code")
      //     : Yup.number().label("code"),
      // password:
      //   loginWith.value === "password"
      //     ? Yup.string().required().label("password")
      //     : Yup.string().label("password"),
    });

    const onSubmitLogin = async (values) => {
      errorsBag.value = {};

      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      // store.dispatch(Actions.LOGIN, values);

      try {
        await store.dispatch(Actions.LOGIN, values)
        router.push("/dashboard");
      } catch (error) {
        // code.value = {}
        reset()
        // console.error("Error occurred while logging in:", error);
      } finally {
        if (submitButton.value) {
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        }
      }
    };

    const sendCode = () => {
      // send code
      let form = new FormData();
      form.append("mobile", props.authData?.mobile);
      ApiService.post("auth/send-code", form)
        .then((res) => {
          //
          console.log(res.data);
          
        if (res.data.data.user_exists === false) {
          emit('returnRegister')
        }
        else{
           loginWith.value = 'opt';
        }
        })
        .catch(() => {
          Swal.fire({
            text: translate(
              "Unfortunately there was an error sending the verification code"
            ),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: translate("Try again"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    onMounted(() => {
      if (props.authData?.loginWith === "code") sendCode();
    });

    watch(props, () => {
      if (loginWith.value === "code") sendCode();
    });

    const getCodes = (newCode) => {
      code.value = newCode;
      if (newCode) {
        onSubmitLogin({mobile :  props.authData?.mobile , code : code.value})
      }
    };
    return {
      onSubmitLogin,
      login,
      submitButton,
      errorsBag,
      translate,
      code,
      loginWith,
      sendCode,
      getCodes,
      reset,
      resetOtp,
    };
  },
});
