
import { defineComponent, onMounted, ref } from "vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

import { translate } from "@/core/services/HelpersFun";
import Base from "@/views/crafted/authentication/basic-flow/Base.vue";
import SendActivationCode from "@/views/crafted/authentication/basic-flow/SendActivationCode.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Code from "@/components/Phone/Code.vue";

export default defineComponent({
  name: "auth-register",
  components: {
    Field,
    Form,
    ErrorMessage,
    Base,
    SendActivationCode,
    Code,
  },
  props: {
    authData: Object,
  },
  setup(props) {
    const errorsBag = ref({});
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const refId = ref();
    const states = ref();

    const cats_model = ref(false);

    const business_category = ref();
    const activitys = ref([
      {
        lable:
          "آموزش و تدریس: شامل آموزشگاه‌ها، پیج‌های آموزشی، مدرسین خصوصی و آنلاین.",
        value: "education_teaching",
      },
      {
        lable:
          "تبلیغات و بازاریابی دیجیتال: آژانس‌های تبلیغاتی، شرکت‌های دیجیتال مارکتینگ، مشاوران کسب‌وکار و بازاریابی.",
        value: "digital_marketing",
      },
      {
        lable: "سفر و مهاجرت: آژانس‌های مسافرتی، خدمات گردشگری، مهاجرتی و ویزا",
        value: "travel_migration",
      },
      {
        lable:
          "سلامت و درمان: کلینیک‌های پزشکی، روانپزشکی، مشاوره، پرستاری و خدمات بهداشتی",
        value: "healthcare_services",
      },
      {
        lable:
          "فناوری و IT: شرکت‌های نرم‌افزاری، سخت‌افزاری، برنامه‌نویسی و طراحی وب",
        value: "technology_it",
      },
      {
        lable: "املاک و خودرو: خرید و فروش املاک، وسایل نقلیه و خودرو",
        value: "real_estate_auto",
      },
      {
        lable:
          "خدمات مالی و حقوقی: شرکت‌های بیمه، مشاوره‌های مالی، خدمات حقوقی و اداری، ثبتی",
        value: "finance_legal",
      },
      {
        lable:
          "خدمات فنی و تعمیراتی: شرکت‌های خدماتی، تعمیراتی، تأسیساتی و نظافتی",
        value: "technical_repairs",
      },
      {
        lable:
          "خرده‌فروشی و فروشگاه‌ها: فروشگاه‌های خرده‌فروشی کالاهای مصرفی، اداری، صنعتی و لوازم خانگی",
        value: "retail_stores",
      },
      {
        lable:
          "کسب‌وکارهای کوچک و خانگی: کسب‌وکارهای شخصی، اینستاگرامی، خانگی و محلی",
        value: "small_home_business",
      },
      {
        lable: "صنعت و تولید: کارخانجات تولیدی و صنعتی",
        value: "industry_manufacturing",
      },
      {
        lable: "زیبایی و بهداشت: سالن‌های زیبایی، خدمات آرایشی و بهداشتی",
        value: "beauty_wellness",
      },
      {
        lable: "غذا و نوشیدنی: کافه‌ها، رستوران‌ها، قنادی‌ها و خدمات پذیرایی",
        value: "food_beverage",
      },
      {
        lable: "خدمات خودرو: تعمیرات و خدمات وسایل نقلیه و قطعات یدکی",
        value: "auto_services",
      },
      {
        lable: "فرهنگ و هنر: کسب‌وکارهای هنری، صنایع دستی و فرهنگی",
        value: "culture_arts",
      },
      // { lable: "سایر", value: "" },
    ]);

    const familiarity_method = ref([
      "google",
      "instagram",
      "linkedin",
      "friends",
      "exhibition",
      "other",
    ]);
    const new_active = ref();
    // const selectedState = ref(1);
    const selectedMethod = ref("");
    // const gender = ref("man");

    const getDeviceType = () => {
      return navigator.userAgent;
    };

    const add_activity = () => {
      activitys.value.push({
        lable: new_active.value,
        value: new_active.value,
      });
      cats_model.value = false;
    };

    const utm = ref(sessionStorage.getItem("utm"));

    const getStates = () => {
      ApiService.get("state").then((data) => {
        states.value = data.data.data.states;
      });
    };

    const submitButtonRegister = ref<HTMLButtonElement | null>(null);
    const register = Yup.object().shape({
      first_name: Yup.string().required("نام الزامی است").label("first_name"),
      last_name: Yup.string()
        .required("نام خانوادگی الزامی است")
        .label("last_name"),
      familiarity_method: Yup.string()
        .required("شیوه‌ی آشنایی را تعیین کنید")
        .label("familiarity_method"),
        business_category: Yup.string()
        .required(" حوزه فعالیت را تعیین کنید")
        .label("business_category"),
      // gender: Yup.string().required("انتخاب جنسیت الزامی است").label("gender"),
      email: Yup.string().label("ایمیل"),
      password: Yup.string()
        .min(8, "حداقل باید 8 کاراکتر وارد کنید")
        // .matches(
        //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        //   translate(
        //     "The password must contain at least one uppercase letter one lowercase letter one digit and have a minimum length of 8 characters"
        //   )
        // )
        .required("رمزعبور الزامی است")
        .label("confirm"),

      password_confirmation: Yup.string()
        .required("تکرار رمزعبور الزامی است")
        .oneOf([Yup.ref("password"), null], translate("Passwords must match"))
        .label("Password Confirmation"),
      // state_id: Yup.number().label("state_id"),
      ref_id: Yup.number().label("ref_id"),
      country_id: Yup.string().label("country_id"),

      code: Yup.string().required("کد الزامی است").label("code"),
    });

    const onSubmitRegister = async (values) => {
      errorsBag.value = {};
      store.dispatch(Actions.LOGOUT);

      if (submitButtonRegister.value) {
        // eslint-disable-next-line
        submitButtonRegister.value!.disabled = false;
        // Activate indicator
        submitButtonRegister.value.setAttribute("data-kt-indicator", "on");
      }

      try {
        await store.dispatch(Actions.REGISTER, values);
        router.push("/welcome-wizard");
        // router.push("/bot-connections");  // mhdn
      } catch (error) {
        return;
      } finally {
        if (submitButtonRegister.value) {
          //Deactivate indicator
          submitButtonRegister.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButtonRegister.value!.disabled = false;
        }
      }
    };

    const StoreUserRefId = () => {
      if (refId.value) window.localStorage.setItem("unique_id", refId.value);
    };
    const code = ref();
    const send = () => {
      // send code
      let form = new FormData();
      form.append("mobile", props?.authData?.mobile);
      ApiService.post("auth/send-code", form)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: translate(
              "Unfortunately there was an error sending the verification code"
            ),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: translate("Try again"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    onMounted(() => {
      getStates();
      send();
      if (route.params.ref) {
        refId.value = route.params.ref;
        StoreUserRefId();
      }
    });

    const getCodes = (newCode) => {
      code.value = newCode;
    };

    return {
      onSubmitRegister,
      register,
      submitButtonRegister,
      errorsBag,
      // selectedState,
      // gender,
      states,
      familiarity_method,
      selectedMethod,
      getDeviceType,
      StoreUserRefId,
      refId,
      route,
      translate,
      code,
      getCodes,
      utm,
      business_category,
      activitys,
      cats_model,
      new_active,
      add_activity,
    };
  },
});
