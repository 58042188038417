import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "min-w-200px min-w-md-400px mw-500px" }
const _hoisted_2 = { key: 3 }
const _hoisted_3 = { class: "alert alert-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SendCode = _resolveComponent("SendCode")!
  const _component_Login = _resolveComponent("Login")!
  const _component_Register = _resolveComponent("Register")!
  const _component_ErrorHandling = _resolveComponent("ErrorHandling")!
  const _component_OfflineOrOnline = _resolveComponent("OfflineOrOnline")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.authData.level === 'sendCode')
      ? (_openBlock(), _createBlock(_component_SendCode, {
          key: 0,
          onCheckUserAuthStatus: _ctx.checkUserAuthStatus,
          onAuthpass: _ctx.authpass
        }, null, 8, ["onCheckUserAuthStatus", "onAuthpass"]))
      : (_ctx.authData.level === 'login')
        ? (_openBlock(), _createBlock(_component_Login, {
            key: 1,
            authData: _ctx.authData,
            onReturnBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.authData.level = 'sendCode')),
            onReturnRegister: _cache[1] || (_cache[1] = ($event: any) => (_ctx.authData.level = 'register'))
          }, null, 8, ["authData"]))
        : (_ctx.authData.level === 'register')
          ? (_openBlock(), _createBlock(_component_Register, {
              key: 2,
              authData: _ctx.authData,
              onReturnBack: _cache[2] || (_cache[2] = ($event: any) => (_ctx.authData.level = 'sendCode'))
            }, null, 8, ["authData"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", null, _toDisplayString(_ctx.translate(
              "Unfortunately a problem has occurred Refresh the site to fix the problem"
            )), 1)
              ])
            ])),
    _createVNode(_component_ErrorHandling),
    _createVNode(_component_OfflineOrOnline)
  ]))
}