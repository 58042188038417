
import { defineComponent, ref, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import SendCode from "@/views/crafted/authentication/basic-flow/component/SendCode.vue";
import Login from "@/views/crafted/authentication/basic-flow/component/Login.vue";
import Register from "@/views/crafted/authentication/basic-flow/component/Register.vue";
import ErrorHandling from "@/components/ErrorHandling.vue";
import { translate } from "@/core/services/HelpersFun";
import OfflineOrOnline from "@/components/OfflineOrOnline.vue";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "sign-in",
  components: {
    SendCode,
    Login,
    Register,
    ErrorHandling,
    OfflineOrOnline,
  },
  setup() {
     const router = useRouter();
    const authData = ref({
      level: "sendCode",
      mobile: "",
      phone_data: {},
      loginWith: "password",
    });

    const checkUserAuthStatus = (data) => {
      authData.value.mobile = data.mobile;
      authData.value.level = data.level;
      authData.value.phone_data = data.phone_data;
      authData.value.loginWith = data.loginWith;
    };
    const authpass = (data, level) => {
      authData.value.level = level;
      authData.value.mobile = data.mobile;
      authData.value.phone_data = data.phone_data;
      // authData.value.loginWith = 'password';
    };


    onBeforeMount(async () => {
     if (JwtService.getToken()) {
      router.push({ name: "dashboard" });
      return;
    }
    });

    return {
      authData,
      checkUserAuthStatus,
      translate,
      authpass,
    };
  },
});
