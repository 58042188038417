
import { defineComponent, onMounted, ref } from "vue";
// import { Form } from "vee-validate";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Field, Form } from "vee-validate";
// import Code from "@/components/Phone/Code.vue";
// import SendActivationCode from "@/views/crafted/authentication/basic-flow/SendActivationCode.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";

import { translate } from "@/core/services/HelpersFun";
import Base from "@/views/crafted/authentication/basic-flow/Base.vue";
import PhoneInput from "@/components/Phone/PhoneInput.vue";
// import Login from "@/views/crafted/authentication/basic-flow/component/Login.vue";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "auth-send-code",
  components: {
    Form,
    Field,
    // Code,
    // SendActivationCode,
    Base,
    PhoneInput,
    // Login,
  },
  props: {
    type: {
      type: String,
      default: "login",
    },
  },
  emit: ["checkUserAuthStatus"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const loginWith = ref("");

    const formData = ref({
      phone_data: {},
      phone_code: "",
      mobile: "",
      level: "",
      loginWith: "code", // code | password
    });
    const code = ref();
    const mobile = ref();

    const submitButton = ref<HTMLButtonElement | null>(null);
    const errorsBag = ref({});

    const check_login = () => {
      // formData.value.mobile = formData.value.mobile;
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // check user auth status
      ApiService.post("auth/check-login", formData.value)
        .then((data) => {
          window.localStorage.setItem("app_key", data.data.data.app_key);
          formData.value.level = data.data.data.status;
          // formData.value.loginWith = data.data.data.is_password_active
          //   ? "password"
          //   : "code";

          emit("checkUserAuthStatus", formData.value);
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          if (submitButton.value) {
            //Deactivate indicator
            submitButton.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
          }
        });
    };

    const onSubmitLogin = async (values) => {
      errorsBag.value = {};

      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      // store.dispatch(Actions.LOGIN, values);

      try {
        await store.dispatch(Actions.LOGIN, values);
        router.push("/bot-connections");
      } catch (error) {
        // console.error("Error occurred while logging in:", error);
      } finally {
        if (submitButton.value) {
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        }
      }
    };

    const sendCode = () => {
      // send code
      let form = new FormData();
      form.append("mobile", formData.value.mobile);
      ApiService.post("auth/send-code", form)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: translate(
              "Unfortunately there was an error sending the verification code"
            ),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: translate("Try again"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const getCodes = (newCode) => {
      code.value = newCode;
    };

    const handlePhone = (phoneObject) => {
      formData.value.phone_code = phoneObject.countryCallingCode;
      formData.value.mobile = phoneObject.number;
      formData.value.phone_data = phoneObject;
      mobile.value = phoneObject.number;
    };

    const with_password = () =>{
 emit("authpass",  formData.value ,'login');

    }

    // const checkKeyBoardLang = () => {
    //   ElNotification({
    //     title: translate("pay attention"),
    //     message: translate(
    //       "لطفا زبان کیبورد خود را برو روی EN (انگلیسی) قرار دهید."
    //     ),
    //     type: "warning",
    //     showClose: false,
    //     duration: 10000,
    //   });
    // };

    onMounted(() => {
      // checkKeyBoardLang();
    });


    return {
      check_login,
      submitButton,
      formData,
      translate,
      handlePhone,
      loginWith,
      sendCode,
      code,
      mobile,
      onSubmitLogin,
      getCodes,
      with_password,
    };
  },
});
